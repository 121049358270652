import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { AuthGuard } from './auth.guard';

const childrenRoutes: VexRoutes = [
  {
    path: 'dashboard',
    redirectTo: '/dashboard'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/menu'
  },
  {
    path: '',
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'menu',
        loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'menu/create',
        loadChildren: () => import('./pages/menu/menu-create-update/menu-create-update.module').then(m => m.MenuCreateUpdateModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'menu/update/:id',
        loadChildren: () => import('./pages/menu/menu-create-update/menu-create-update.module').then(m => m.MenuCreateUpdateModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'option',
        loadChildren: () => import('./pages/options/options.module').then(m => m.OptionsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'option/create',
        loadChildren: () => import('./pages/options/options-create-update/options-create-update.module').then(m => m.OptionsCreateUpdateModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'option/update/:id',
        loadChildren: () => import('./pages/options/options-create-update/options-create-update.module').then(m => m.OptionsCreateUpdateModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'language',
        loadChildren: () => import('./pages/language/language.module').then(m => m.LanguageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'language/create',
        loadChildren: () => import('./pages/language/language-create-update/language-create-update.module').then(m => m.LanguageCreateUpdateModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'language/update/:id',
        loadChildren: () => import('./pages/language/language-create-update/language-create-update.module').then(m => m.LanguageCreateUpdateModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'user',
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'user/create',
        loadChildren: () => import('./pages/user/user-create-update/user-create-update.module').then(m => m.UserCreateUpdateModule),
        canActivate: [AuthGuard],
      },

      {
        path: 'newsletter',
        loadChildren: () => import('./pages/newsletter/newsletter.module').then(m => m.NewsletterModule),
        canActivate: [AuthGuard],
      },
    ]
  }
];


const routes: Routes = [
  {
    path: '',
    component: CustomLayoutComponent,
    children: childrenRoutes
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
